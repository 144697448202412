import { format, isToday, addMinutes } from 'date-fns';
import { APPOINTMENT_OFFSET_MINUTES } from '@/data/constants';
import http from '../httpConfig';

const handleData = (data, isToday) => {
  const now = parseInt(format(addMinutes(new Date(), APPOINTMENT_OFFSET_MINUTES), 'Hmm'), 10);

  const timetable = Object.keys(data)
    .map((key) => ({
      time: data[key],
      label: String(data[key]).slice(0, -3),
    }));

  return isToday
    ? timetable
      .filter((item) => {
        const time = parseInt(item.time.replace(':', '').slice(0, -2), 10);
        return time > now;
      })
    : timetable;
};

const doctorAgenda = (config) => {
  const headers = config;

  const fetchOfficeSchedule = (
    officeId,
    startDate,
    type,
    paymentMethod = 'creditCard',
  ) => new Promise((resolve, reject) => {
    const paymentMethodString = paymentMethod === 'creditCard'
      ? 'CARTÃO DE CRÉDITO'
      : 'BOLETO';

    const params = [
      { query: 'initialDate', value: `${startDate} 00:00:00` },
      { query: 'finalDate', value: `${startDate} 23:59:59` },
      { query: 'type', value: type },
      { query: 'paymentMethod', value: paymentMethodString },
    ];

    const queryString = params.reduce(
      (acc, item) => `${acc.startsWith('?') ? `${acc}&` : '?'}${item.query}=${item.value}`,
      '',
    );

    const url = `/doctor_office/${officeId}/agenda${queryString}`;

    http.get(url, headers)
      .then((res) => {
        const notAvailable = () => reject(new Error(
          'Não foram encontrados horários disponíveis para a especialidade escolhida.',
        ));

        if (
          typeof res.data === 'object'
          && Object.keys(res.data).includes(startDate)
        ) {
          const [year, month, day] = startDate.split('-');
          const today = new Date(year, month - 1, day);
          const timetable = handleData(res.data[startDate], isToday(today));

          if (timetable.length === 0) {
            notAvailable();
            return;
          }

          resolve(timetable);
          return;
        }

        resolve(res.data);
      })
      .catch(reject);
  });

  const fetchAllOffices = (doctorId) => new Promise((resolve, reject) => {
    const url = `/user/doctor/${doctorId}/doctor_office?filterAnd[ai.isActive]=1`;

    http.get(url, headers)
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.response.data));
  });

  return {
    fetchOfficeSchedule,
    fetchAllOffices,
  };
};

export default doctorAgenda;
